<template>
  <div>
    <van-nav-bar
        title="提现"
        :border="false"
        left-arrow
        @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-form @submit="onSubmit">
      <van-field
          v-model="amount"
          name="提现金额"
          label="提现金额"
          placeholder="提现金额"
      />
      <van-field
          readonly
          clickable
          name="picker"
          :value="account"
          label="选择器"
          placeholder="点击选择账号"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="list"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
          v-model="message"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
      >
        <template #button>
          <img @click="getImg" :src="imgUrl">
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button @click="onSubmit" round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {getCardList,cashWithdrawal,getImgCode} from "@/api/user";
import countDown from "@/components/countDown/countDown";
import {Toast} from "vant";
export default {
  name: "withdrawal",
  components:{
    countDown
  },
  data() {
    return {
      account: '',
      list: [],
      showPicker: false,
      amount:null,
      message:'',
      imgUrl:'',
      key:null
    }
  },
  created() {
    getCardList({}).then((res) => {
      if (res.code == 0) {
        const arr=res.result||[]
        this.list=arr.map((item)=>{
           return{
             text:item.cardId,
             value:item.cardId
           }
        })
      }
    })
    this.getImg()
  },
  methods: {
    onConfirm(value) {
      this.account = value.value;
      this.showPicker = false;
    },
    onSubmit(){
      if (!this.amount)
      {
        return this.$toast.fail('请输入提现金额')
      }
      if (!this.account)
      {
        return this.$toast.fail('请选择提现账号')
      }
      if (!this.message)
      {
        return this.$toast.fail('请输入验证码')
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      cashWithdrawal({"cardId":this.account,"money":this.amount,"code":this.message,"key":"554122"}).then((res) => {
        if (res.code == 0) {

        }
      })
    },
    getImg(){
      this.key=new Date().getTime()
      getImgCode(this.key).then((res)=>{
        this.imgUrl=res.data.result
      })
    }
  },
}
</script>

<style scoped>

</style>